<template>
  <div class="accountWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="rechargeWrap mb20" :style="{backgroundImage: 'url('+rechargeBg+')'}">
          <div class="userBar">
            <div class="titleWrap">
              <div class="title">
                <span class="txt">充值中心</span>
                <span class="back" @click="goBack">返回上级</span>
              </div>
              <div class="titleTip">适度娱乐 理性消费</div>
            </div>
            <div class="userInfo">
              <div class="item"><span class="tit">游戏账号：</span><span class="txt">{{userInfo.username}}</span></div>
              <div class="item"><span class="tit">游戏大区：</span><span class="txt">{{userInfo.gameArea}}</span></div>
              <div class="item"><span class="tit">白金币余额：</span><span class="txt">{{userInfo.wallteInfo.chargeMoney}} </span></div>
              <div class="rechargeBtnWrap"><span class="rechargeBtn" @click="goRecord">充值记录</span></div>
            </div>
            <div class="rechargeBar clearfix">
              <div class="rechargMy fl">
                <div class="item" style="cursor: pointer" v-for="(item,index) in rechargMyList" @click="goPay(item,'0')">
                  <template v-if="item.isFirst">
                    <img class="recommend" :src="require('@/assets/imgs/recharge/isFirstFlag.png')" alt="">
                    <div class="giftMoney">
                     首充</br>
                     送{{item.giftMoney}}
                    </div>
                  </template>
                  <span class="icon">{{item.giftMoney}}白金币</span>
                  <span class="money">{{item.money}}元</span>
                </div>
              </div>
              <div class="rechargOther fr">
                <div class="tit">自定义充值</div>
                <el-form ref="payForm" :rules="payRules" :model="rechargOtherForm" label-width="100px" :hide-required-asterisk="true">
                  <el-form-item label="游戏账号:" prop="username">
                    <el-input v-model="rechargOtherForm.username" placeholder="请输入充值账号"></el-input>
                  </el-form-item>
                  <el-form-item label="确认账号:" prop="comfigName">
                    <el-input v-model="rechargOtherForm.comfigName" placeholder="请再次输入充值账号"></el-input>
                    <div class="comfigNameTip">请谨慎选择，充值后无法退还！</div>
                  </el-form-item>
                  <el-form-item label="输入金额:" prop="money">
                    <el-input v-model="rechargOtherForm.money" placeholder="请输入大于等于1的整数"></el-input>
                    <div class="moneyTip">请输入大于等于1的整数金额！</div>
                  </el-form-item>
                  <el-form-item class="submitWrap">
                    <img :src="rechargeBtn" style="cursor: pointer" @click="onSubmitPay('payForm')" alt="">
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <userInfoWX></userInfoWX>
        </div>
        <div class="downCons bgWhite">
          <downItemWX :type="2"></downItemWX>
        </div>
      </div>
      <orderModal v-if="isShowOrderModal" :isShowOrderModal="isShowOrderModal" :payParams="payParams" @closeModal="closeModal" @initData="initData"></orderModal>
    </div>
  </div>
</template>
<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'

  import userInfoWX from '@/components/userInfo'
  import orderModal from './orderModal'

  import {publicUrl} from '@/common/publicUrl.js'

  import axios from "axios";
  import Cookies from "js-cookie";

  export default {
    name: 'account',
    components:{
      menuWX,
      downItemWX,
      orderModal,
      userInfoWX,
    },
    data(){
      return {
        isShowOrderModal:false,
        payParams:{},
        rechargeBg:require('@/assets/imgs/recharge/bg.png'),
        rechargeBtn:require('@/assets/imgs/recharge/rechargeBtn.png'),
        rechargMyList:[],//充值列表
        rechargOtherForm:{        // 自定义充值
          username:'',
          comfigName:'',
          money:'',
        },
        payRules: {
          username: [
            { required: false, message: '请输入充值账号', trigger: 'blur' },
          ],
          comfigName: [
            { required: false, message: '请再次输入充值账号', trigger: 'blur' },
          ],
          money: [
            { required: false, message: '请输入大于等于1的整数金额！', trigger: 'blur' }
          ],
        },
        userInfo:{
          verifyInfo:{},
          vipInfo:{},
          wallteInfo:{},
        },
        downDatas:[]
      }
    },
    created() {

    },
    mounted() {
      let _this = this
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      window.addEventListener('setItem', ()=> {
        _this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      });
      this.getRechargeList()      // 获取充值列表
      document.documentElement.scrollTop = 0
    },
    methods: {
      goBack(){
        this.$router.go(-1)
      },
      getRechargeList(){
        axios({
          method: 'post',
          url: '/recharge/getRechargeList',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            if(res.data.data.rechargeInfos.length>0){
              this.rechargMyList = res.data.data.rechargeInfos.slice(0,8);
            }
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },        // 获取充值列表
      goPay(item,rechargeMode){
        this.isShowOrderModal = true;
        this.payParams = item;
        this.payParams.rechargeMode = rechargeMode;
      }, // 打开支付弹窗
      onSubmitPay(formName){
        var reg = /^[1-9][0-9]*$/;
        if(!this.rechargOtherForm.username.trim()){
          this.$message.error('请输入游戏账号')
        }else if(!this.rechargOtherForm.comfigName.trim()){
          this.$message.error('请再次输入充值账号')
        }else if(this.rechargOtherForm.username!==this.rechargOtherForm.comfigName){
          this.$message.error('两次输入的账号不一致')
        }else if(!(reg.test(this.rechargOtherForm.money))){
          this.$message.error('请输入大于等于1的整数')
        } else if(this.rechargOtherForm.money.trim()>10000){
          this.$message.error('单笔充值金额上限为10000元')
        }else{
          this.isShowOrderModal = true;
          this.payParams = this.rechargOtherForm;
          this.payParams.rechargeMode = '1';
        }
      },    // 充值表单校验
      closeModal(){
        this.isShowOrderModal = false;
      },             // 关闭充值弹窗
      initData(){
        this.$router.go(0); // 刷新当前页
      },               // 初始化页面
      goRecord(){
        this.$router.push('/record?type=1')
      },               // 进入充值记录
      getMemberInfo(){
        axios({
          method: 'post',
          url: '/user/getMemberInfo',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.userInfo = res.data.data;
            this.resetSetItem('userInfo', JSON.stringify(res.data.data))
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },          // 获取用户信息
    },
    beforeDestroy() {
      this.wechatImg = false;
      window.clearInterval(this.timer); // 清除定时器
      this.timer = null;
    },
  }
</script>
<style lang="less">
  .accountWrap{
    .divideWrap{
      .el-form-item{
        margin-bottom: 5px;
      }
      .el-form-item__label{
        font-size: 18px;
        font-weight: 700;
        line-height: 36px;
      }
      .el-form-item__content{
        line-height: 36px;
      }
      .el-input__inner{
        border-radius: 0;
        border-color: #333333;
        height: 36px;
        line-height: 36px;
      }
      .codeWrap{
        .el-input__inner{
          width: 132px;
        }
      }
      .submitWrap{
        .btn{
          padding: 0;
          width: 132px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
    .bubbleWrap{
      .bubbleInfo{
        .el-radio__inner{
          width: 20px;
          height: 20px;
          &::after{
            width: 8px;
            height: 8px;
          }
        }
        .el-radio__label{
          font-size: 20px;
        }
      }
    }
    .rechargeBar{
      .rechargOther{
        .el-form-item {
          margin-bottom: 10px;
        }
        .el-form-item__label{
          font-size: 18px;
          line-height: 36px;
          color: #333333;
        }
        .el-form-item__content{
          line-height: 36px;
        }
        .el-input__inner{
          height: 36px;
          line-height: 36px;
        }
        .submitWrap{
          height: 60px;
          text-align: center;
          .el-form-item__content{
            margin:0!important;
          }
        }
      }
    }
  }
</style>
<style lang="less" scoped>
  .accountWrap{
    .rechargeWrap{
      padding: 40px 30px;
      height: 532px;
      box-sizing: border-box;
      .userBar{
        .titleWrap{
          padding:10px;
          text-align: center;
          border-bottom: 1px solid #333333;
          .title{
            position: relative;
            z-index: 1;
            .txt{
              font-size: 30px;
            }
            .back{
              position: absolute;
              right: 0;
              bottom: 10px;
              z-index:2;
              font-size: 20px;
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .titleTip{
            padding-top:3px;
            color: #FF0000;
          }
        }
        .userInfo{
          display: flex;
          align-items: center;
          .item{
            margin-right: 20px;
            padding:15px 0;
            display: flex;
            align-items: center;
            .tit{
              font-size: 18px;
              white-space: nowrap;
            }
            .txt{
              display: inline-block;
              padding:5px;
              font-size: 18px;
              width: 140px;
              min-width: 140px;
              border-bottom: 1px dashed #333333;
            }
          }
          .rechargeBtnWrap{
            .rechargeBtn{
              display: inline-block;
              width: 90px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background: #D22190;
              border-radius: 4px;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
        .rechargeBar{
          .rechargMy{
            padding:6px;
            width: 522px;
            border:1px dashed #999999;
            overflow: hidden;
            box-sizing: border-box;
            .item{
              float: left;
              margin: 12px 7px;
              padding-top:85px;
              padding-right: 10px;
              width: 113px;
              height: 130px;
              box-sizing: border-box;
              text-align: center;
              background-image: url('../../assets/imgs/recharge/rechargeBg.png');
              position: relative;
              background-repeat: no-repeat;
              .icon{
                display: block;
                font-size: 12px;
                margin:3px 0;
                color: #D22190;
                font-weight: 700;
              }
              .recommend{
                position: absolute;
                left: -2px;
                top: -10px;
              }
              .giftMoney{
                position: absolute;
                right: 0px;
                top: -12px;
                color: #ffffff;
                font-size: 12px;
                display: inline-block;
                padding-top:8px;
                width: 46px;
                height: 46px;
                line-height: 14px;
                background: #D22190;
                box-shadow: 0 0 6px 0 rgba(210,33,144,0.5);
                border-radius: 50%;
                box-sizing: border-box;
                transform: scale(.9);
              }
              .money{
                display: inline-block;
                width: 84px;
                height: 24px;
                line-height: 24px;
                color: #fff;
                border-radius: 24px;
                box-shadow: 0px 2px 4px 0px rgba(252,102,150,0.5);
                opacity: 1;
                background:#FC6696;
              }
            }
          }
          .rechargOther {
            padding:16px;
            width: 347px;
            border:1px dashed #999999;
            box-sizing: border-box;
            .tit{
              margin-bottom: 10px;
              text-align: center;
              font-size: 22px;
              font-weight: 700;
            }
            .comfigNameTip,.moneyTip{
              margin-bottom: 0;
              line-height: 24px;
              color: #FF0000;
            }
          }
        }
      }
    }
    .downCons{
      box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255,255,255,0.25);
      padding-top: 50px;
    }
  }
</style>
