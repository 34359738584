<template>
  <div class="borderModal">
    <el-dialog :visible.sync="isShowOrderModal" :close-on-press-escape="false" :close-on-click-modal="false"
      width="555px">
      <div class="border">
        <img class="closed" @click="closeModal" :src="closed" alt="">
        <div class="title">订单确认</div>
        <div class="mobileWrap changeInfo">
          <el-form ref="orderForm" :model="orderForm" :rules="orderFormRules" label-width="100px">
            <el-form-item label="充值账号:" prop="username"><span class="rechargeName">{{ orderForm.username
                }}</span></el-form-item>
            <el-form-item label="充值金额:" class="telWrap" prop="money"><el-input v-model="orderForm.money"
                :disabled="true" placeholder="请输入充值金额"></el-input></el-form-item>
            <div class="">
              <div class="gitCode">可获得：{{ payParams.money }}白金币 <template v-if="payParams.isFirst"><span
                    style="font-size: 14px;">（额外赠送{{ payParams.giftMoney }}绑定白金币）</span></template>
              </div>
              <div class="payBox">
                <p class="center fw500 c3 fs20" style="margin:15px 0 20px">支付方式选择</p>
                <el-radio-group v-model="orderForm.type">
                  <div class="item" v-for="(item, index) in orderPayList">
                    <div class="pic"><img :src="item.picUrl" alt="" @click="payChange(item)"></div>
                    <el-radio :label="item.name"></el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <el-form-item class="submitWrapBar">
              <div class="submitWrap">
                <div class="btnWrap" @click="submitForm('orderForm')"><span>确定充值</span></div>
                <p class="tips">请仔细确认账单后支付！</p>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="wechatPay" v-if="wechatImg">
          <div id="wechatPay"></div>
          <div
            style="display:inline-block;width:300px;height:30px;line-height:30px;font-size: 20px;color: #333333;background:#ffffff">
            微信扫码支付</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import eventBus from '@/common/bus.js'

export default {
  name: 'pubMask',
  props: {
    isShowOrderModal: false,
    payParams: {}
  },
  data() {
    return {
      userInfo: {
        verifyInfo: {},
        vipInfo: {},
        wallteInfo: {},
      },            // 用户信息

      timer: null,
      firstTimer: null,
      douwCount: 0,
      wechatImg: false,
      orderForm: {
        username: '', // 账号
        money: this.payParams.money,    // 金额
        type: '2', //支付方式
      },
      orderPayList: [
        { id: '2', name: '2', picUrl: require('@/assets/imgs/recharge/wechat.png') },
        { id: '1', name: '1', picUrl: require('@/assets/imgs/recharge/alipay.png') },
      ],
      orderFormRules: {
        username: [
          { required: true, message: '请输入充值账号', trigger: 'blur' },
        ],
        money: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
        ],
      },
      closed: require('@/assets/imgs/public/close.png'),
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.orderForm.username = this.payParams.username || this.userInfo.username
  },
  methods: {
    submitForm(orderForm) {
      this.$refs[orderForm].validate((valid) => {
        if (valid) {
          console.log('通过', valid);
          this.goPay();
        } else {
          console.log('校验未通过', valid);
          return false;
        }
      });
    },
    payChange(item) {
      this.orderForm.type = item.name == '2' ? '2' : '1'
    },
    goPay() {
      this.douwCount = 0
      axios({
        method: 'post',
        url: '/recharge/doPay',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          money: Number(this.orderForm.money),
          payId: this.orderForm.type, //微信2, 支付宝1
          platForm: 'web',
          rechargeId: this.payParams.rechargeId || '99', //充值金额id
          rechargeMode: this.payParams.rechargeMode, //0代表正常充值, 1自定义充值
          userId: Cookies.get('wx_userId'),
          username: this.orderForm.username,
        },
      }).then((res) => {
        let that = this
        if (res.data.state == 200) {
          if (res.data.data.source == '9i173') {

            if (res.data.data.payId == 1) {
              // 支付宝支付
              that.aorderSn = res.data.data.orderSn; //订单id
              // const div = document.createElement('formdiv');
              console.log('9i173', res.data.data.data);

              window.open(res.data.data.data);
            } else {
              // 微信支付
              that.aorderSn = res.data.data.orderSn; //订单id
              that.wechatImg = true;
              setTimeout(() => {
                var divID = document.getElementById("wechatPay");
                divID.innerHTML = res.data.data.data;
              }, 100)
            }

          } else {
            if (res.data.data.payId == 1) {
              // 支付宝支付
              that.aorderSn = res.data.data.orderSn; //订单id
              const div = document.createElement('formdiv');
              console.log(res.data.data.data);
              div.innerHTML = res.data.data.data;
              document.body.appendChild(div);
              document.forms['punchout_form'].setAttribute('target', '_blank');
              document.forms['punchout_form'].submit();
              div.remove();
            } else {
              // 微信支付
              that.aorderSn = res.data.data.orderSn; //订单id
              that.wechatImg = true;
              setTimeout(() => {
                var divID = document.getElementById("wechatPay");
                divID.innerHTML = res.data.data.data;
              }, 100)
            }
          }

          that.firstTimer = setTimeout(() => {
            that.timer = setInterval(function () {
              that.queryIsPaySuccess(); // 查询是否支付
            }, 5000);
          }, 10000)

        } else {
          that.$message.error(res.data.message)
        }
      }).catch(err => {
        that.$message.error(err.message)
      })
    },
    queryIsPaySuccess(orderSn) {
      let that = this;
      this.douwCount++
      if (this.douwCount > 30) {
        window.clearInterval(that.timer); // 清除定时器
        that.timer = null;
        window.clearInterval(that.firstTimer); // 清除定时器
        that.firstTimer = null;
        return false
      } else {
        axios({
          method: 'post',
          url: '/recharge/checkOrder',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            orderSn: that.aorderSn,
            userId: Cookies.get('wx_userId'),
            platForm: 'web',
          },
        }).then((res) => {
          if (res.data.state == 200) {
            that.$message.success('支付成功')
            window.clearInterval(that.timer); // 清除定时器
            that.timer = null;
            window.clearInterval(that.firstTimer); // 清除定时器
            that.firstTimer = null;
            that.wechatImg = false;
            setTimeout(() => {
              that.$emit('initData');
            }, 3000)
          }
        }).catch(err => {
          that.$message.error(res.data.message)
        })
      }
    },
    closeModal() {
      let that = this
      if (this.wechatPay) {
        this.$emit('initData');
      } else {
        window.clearInterval(that.timer); // 清除定时器
        that.timer = null;
        window.clearInterval(that.firstTimer); // 清除定时器
        that.firstTimer = null;
        this.$emit('closeModal');
      }
    },
  },
  watch: {
    payParams(newval) {
      this.orderForm.money = newval.money
    }
  },
};
</script>

<style lang="less">
.borderModal {
  .center {
    text-align: center;
  }

  .fs20 {
    font-size: 20px;
  }

  .c3 {
    color: #333;
  }

  .fw500 {
    font-weight: 500;
  }

  .el-dialog {
    min-width: 555px;
    background: transparent;

    .rechargeName {
      display: block;
      width: 220px;
      border-bottom: 1px dashed #333333;
    }

    .submitWrapBar {
      margin-top: 20px;
      margin-bottom: 0 !important;

      .el-form-item__content {
        margin-left: 0 !important;
      }

      .submitWrap {
        text-align: center;

        .tips {
          font-size: 12px !important;
          color: #FF0000;
          margin-top: -15px;
        }

        .btnWrap {
          margin: 0 10px;
          display: inline-block;
          padding: 1px;
          width: 170px;
          height: 44px;
          line-height: 42px;
          box-sizing: border-box;
          border-radius: 44px;
          overflow: hidden;

          span {
            display: inline-block;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #DD8EFF, #FFA3F0);
            border-radius: 46px;
            font-size: 18px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 5px;
      background: linear-gradient(#D7B7FF, #F9F8FF);
      border-radius: 10px;
    }

    .border {
      position: relative;
      z-index: 1;
      padding: 60px 100px;
      background: linear-gradient(#F6F1FF, #D7C5FF);
      border-radius: 10px;
    }

    .closed {
      position: absolute;
      right: -35px;
      top: -38px;
      z-index: 1;
      cursor: pointer;
    }

    .title {
      margin-bottom: 20px;
      text-align: center;
      font-size: 30px;
      color: #333333;
      font-weight: 700;
    }

    .wechatPay {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);

      img {
        margin-top: 110px;
      }
    }

    .el-form-item__label {
      width: 120px;
      font-size: 18px;
      color: #333333;
      font-weight: 700;
      line-height: 36px;

      &:before {
        display: none;
      }
    }

    .el-form-item__content {
      line-height: 36px;
    }

    .el-form-item {
      margin-bottom: 18px !important;
    }

    .el-input {
      height: 36px;
      line-height: 36px;
    }

    .el-input__inner {
      width: 220px;
      height: 36px;
      background: transparent;
      border: 1px solid #333333;
      border-radius: 0;
      padding: 0 6px;

      &::placeholder {
        font-size: 12px;
      }
    }

    .el-input.is-disabled {
      .el-input__inner {
        color: #333333;
      }
    }

    .el-radio-group {
      display: flex;
      justify-content: center;

      .el-radio__label {
        display: none;
      }

      .item {
        margin-right: 20px;
        text-align: center;

        .pic {
          margin-bottom: 10px;
        }
      }
    }

    .el-radio__inner {
      width: 20px;
      height: 20px;
    }

    .gitCode {
      width: 340px;
      height: 42px;
      transform: translateX(-6px);
      margin: 20px auto 0;
      line-height: 42px;
      text-align: center;
      color: #ffffff;
      font-size: 16px;
      background: #A042FF;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }
  }
}
</style>
